import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Spinner from 'components/Spinner';
import 'antd/dist/antd.css';

const App = lazy(() => import('./App'));

const root = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_KEY}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
      scope="openid profile email"
    >
      <Router>
        <Suspense fallback={<Spinner />}>
          <App />
        </Suspense>
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
